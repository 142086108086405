// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("/opt/build/repo/gatsby/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/gatsby/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-comments-success-js": () => import("/opt/build/repo/gatsby/src/pages/comments/success.js" /* webpackChunkName: "component---src-pages-comments-success-js" */),
  "component---src-pages-community-js": () => import("/opt/build/repo/gatsby/src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/gatsby/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

exports.data = () => import("/opt/build/repo/gatsby/.cache/data.json")

